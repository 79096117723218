/* You can add global styles to this file, and also import other style files */
body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  background-color: #f5f5f5;
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  max-height: 100%;
}

#app-loading-spinner {
  &.visible {
    display: block;
    opacity: 1;
  }

  &.hidden {
    display: none;
    opacity: 0;
  }
}
//adyen
.adyen-checkout__spinner {
  border-bottom-color: #407090 !important;
  border-left-color: #407090 !important;
  border-right-color: #407090 !important;
}

@media only screen and (min-width: 800px) {
  .payment-layout .button-wrap {
    width: 500px;
  }
}

// adyen
.payment-layout {
  padding-top: 30px;
}
.adyen-checkout__card__brands__brand-wrapper {
  line-height: 0.7rem;
}
.adyen-checkout__label__text {
  font-size: 18px;
}
.adyen-checkout__button {
  background-color: #4d7399  !important;
  width: 300px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
  border-radius:0 !important;
  font-size: 22px !important;
}
.adyen-checkout__spinner {
  border-bottom-color:#4d7399 !important;
  border-left-color: #4d7399  !important;
  border-right-color:#4d7399  !important;
}