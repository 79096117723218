@import 'variables';

.request-loader {
  position: absolute;
  height: 3px;
  left: 0;
  right: 0;
  z-index: 9999;
  background-color: $success;
  transition: opacity 0.3s, visibility 0.3s;
  overflow: hidden;

  &::before {
    position: absolute;
    height: 100%;
    content: '';
    width: 10vw;
    animation-name: moveShadow;
    animation-iteration-count: infinite;
    animation-duration: 3s;
    background: linear-gradient(to right, rgba($white, 0) 0%, rgba($white, 1) 50%, rgba($white, 0) 100%);
  }

  &.loader-hidden {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes moveShadow {
  0% {
    left: 0;
    transform: translateX(-100%);
  }
  100% {
    left: 100%;
    transform: translateX(0);
  }
}
