/* Variables */

// Image url
$img_url: '/assets/img/';

//
// MZ
//
$spacing-1: 10px;
$spacing-2: 20px;
$spacing-3: 30px;
$spacing-4: 40px;
$spacing-5: 50px;
$spacing-6: 60px;
$spacing-7: 70px;
$spacing-8: 80px;
$spacing-9: 90px;
$spacing-10: 100px;

$color-1: #002c20;
$color-2: #00c793;
$color-3: #00936c;
$color-4: #007152;
$color-5: #00553e;
$color-6: #f2f0ef;

$base-font-size: 16px;
$font-size-small: 0.875rem;
$font-size-large: 1.125rem;
$font-size-h1: 2.8125rem;
$font-size-h1-tablet: 2rem;
$font-size-h1-mobile: 1.75rem;
$font-size-h2: 1.75rem;
$font-size-h2-mobile: 1.375rem;
$font-size-h3: 1.65rem;

$color-body: #21394c;
$color-body-bg: #f5f5f5;
$color-text: var($color-1);
$color-text-light: #fcfcfc;
$color-separator: #d7d7d7;
$color-gray-1: #ccc;
$color-header-bg: #ebebeb;

// height
$footer-height: 299px;
$footer-md-height: 473px;
$footer-sm-height: 672px;
$footer-xs-height: 692px;
$header-height: 125px;
$header-xs-height: 75px;
//$header-xs-height: 135px;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  // change all BREAKPOINT_MD in js
  lg: 992px,
  xl: 1200px,
  xxl: 1550px,
) !default;

// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1040px,
  xxl: 1200px,
) !default;

//$grid-breakpoint-xxl: 1550px;
//$container-xxl-max-width: 1440px;

// Color system
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #e7e7e7 !default;
$gray-400: #f0eff8 !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #555454 !default;
$gray-800: #343a40 !default;
$gray-900: #222 !default;

//$blue: #0078d2 !default;
$blue: #3891bc !default;
$red: #dc3545;
$orange: #f08a24 !default;
$yellow: #ffc107 !default;
$green: $color-3 !default;
$cyan: #a0d3e8 !default;
$dark-blue: #1d2331 !default;

$primary: $dark-blue !default;
$secondary: $gray-700 !default;
$success: $green !default;
$info: $blue !default;
$warning: $orange !default;
$danger: $red !default;

$body-bg: $color-body-bg;
$border-color: $gray-400;
$header-bg: $white;
$footer-bg: $white;

$link-color: #00936c;
$link-header-color: #90afd4;
$link-active-bg: #f5f7fa;
$link-header-border: $blue;

$font-size-xl: 46px;
$font-size-text: 17px;

$font-weight-light: 300;
$font-weight-default: 400;
$font-weight-bold: 600;
$font-weight-extra-bold: 700;

$font-family-title: 'Oxygen', sans-serif;

$banner-height-xl: 450px;
$banner-height-lg: 400px;
$banner-height-md: 380px;
$banner-height-sm: 320px;
$banner-height-xs: 255px;
$banner-bg: #d1e7f2;

$modal-md: 550px !default;
$modal-lg: 700px !default;
$modal-xl: 850px !default;
$modal-inner-padding: 1rem;
$modal-header-padding: 1rem 1rem 0.75rem 1rem;
$modal-dialog-margin-y-sm-up: 5rem;
$modal-shadow-color: rgba(0, 34, 76, 0.1);
$close-font-size: 2rem;

$grid-gutter-width: 1.25rem;
$col-mb: $grid-gutter-width;

$input-font-size: 1.2rem;
$input-border-color: #c1c9d9;
$input-border-radius: 2px;
$input-color: $dark-blue;
$input-padding-y: 0.75rem;
$input-padding-x: 1rem;
$input-btn-focus-width: 0.2rem;
$input-focus-box-shadow: 0 0 0 $input-btn-focus-width rgba($primary, 0.1);

$border-radius: 0;

$card-bg: $white;
$card-color: $dark-blue;
$card-border-color: $body-bg;

$table-bg-odd: $gray-400;
$table-hover-bg: $gray-100;

$status-paid: $success;
$status-unpaid: $danger;
$status-cancelled: $gray-600;
$status-appealed: $info;
$icon-color: #a6aebd;

$color-description: #838fa6;

$btn-font-weight: 400;
$btn-line-height: 1.4rem;
$btn-border-radius-lg: 0;
$btn-padding-y-lg: 1.2rem;
$input-btn-padding-x: 1rem;
$input-btn-padding-y: 0.5rem;

$popup-border-color: #edf0f5;
