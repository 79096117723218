// @font-face {df
//   font-family: 'Myriad Pro';
//   src: url('assets/fonts/MyriadPro-Bold.eot');
//   src: local('Myriad Pro Bold'), local('MyriadPro-Bold'),
//   url('assets/fonts/MyriadPro-Bold.eot?#iefix') format('embedded-opentype'),
//   url('assets/fonts/MyriadPro-Bold.woff') format('woff'),
//   url('assets/fonts/MyriadPro-Bold.ttf') format('truetype');
//   font-weight: bold;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Myriad Pro CD';
//   src: url('assets/fonts/MyriadPro-BoldCond.eot');
//   src: local('Myriad Pro Bold Condensed'), local('MyriadPro-BoldCond'),
//   url('assets/fonts/MyriadPro-BoldCond.eot?#iefix') format('embedded-opentype'),
//   url('assets/fonts/MyriadPro-BoldCond.woff') format('woff'),
//   url('assets/fonts/MyriadPro-BoldCond.ttf') format('truetype');
//   font-weight: bold;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Myriad Pro';
//   src: url('assets/fonts/MyriadPro-Regular.eot');
//   src: local('Myriad Pro Regular'), local('MyriadPro-Regular'),
//   url('assets/fonts/MyriadPro-Regular.eot?#iefix') format('embedded-opentype'),
//   url('assets/fonts/MyriadPro-Regular.woff') format('woff'),
//   url('assets/fonts/MyriadPro-Regular.ttf') format('truetype');
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Myriad Pro';
//   src: url('assets/fonts/MyriadPro-It.eot');
//   src: local('Myriad Pro Italic'), local('MyriadPro-It'),
//   url('assets/fonts/MyriadPro-It.eot?#iefix') format('embedded-opentype'),
//   url('assets/fonts/MyriadPro-It.woff') format('woff'),
//   url('assets/fonts/MyriadPro-It.ttf') format('truetype');
//   font-weight: normal;
//   font-style: italic;
// }

@font-face {
  font-family: 'tolling';
  src: url('../../assets/icons/tolling.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
