@include media-breakpoint-down(xs) {
  h1 {
    font-size: $font-size-h1-mobile;
  }
  h2 {
    font-size: $font-size-h2-mobile;
  }
  .mz-flow.card{
     
    // padding-right: 1.5rem;
  }
  .card-header
  {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 0;
    padding-bottom: 0;
  }

}

.form-date-picker[readonly] {
  background-color: white;
}
ngb-datepicker.dropdown-menu {
  z-index: 1090;
}
.card-footer {
   font-size: 0.75rem;
}

.mh-450{
  min-height: 450px;
}

.header__main .menu__item A {
    position: relative;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    height: 100%;
    color: $color-1;
    font-weight: $font-weight-light;
    text-decoration: none;
  }

.btn.btn--link-back {
  position: absolute;
  margin-left: -20px;
  margin-top: -5px;
  font-size: $font-size-h3;
}

.btn.btn--link-only, .btn.btn--link-back {
  color: $color-3;
  background-color: transparent;
  padding: 0;
  font-weight: $font-weight-light;
  font-size: $base-font-size;
}
.btn.btn--link-only:after {
  content: "\e900";
  font-family: icons-theme!important;
  font-size: $font-size-h2-mobile;
  vertical-align: middle;
  border: 1px solid $color-3;
  border-radius: 50%;
  margin-left:  $spacing-1;
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out;
  position: relative;
  top: -1px;
}

.btn.btn--link-back:before {
  content: "\e900";
  font-family: icons-theme!important;
  font-size: $font-size-h2-mobile;
  vertical-align: middle;
  margin-left:  $spacing-1;
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out;
  position: relative;
  top: -1px;
   transform: rotate(180deg);
}

/* Custom properties */
:root {
    /* Grid */
    --max-width: 1200px;
    --max-width-header: 1200px;
    --max-width-list: 960px;
    --max-width-pagination: 960px;
    --max-width-content: 575px;
    --grid-gutter: 7px;

    /* Typography */
    --base-font-family: 'Rubik', sans-serif;
    --font-family-heading: var(--base-font-family);

    /* Type: Sizes */
    --base-font-size: 16px;
    --base-font-size-mobile: 1.0625rem;
    --font-size-small: 0.875rem;
    --font-size-large: 1.125rem;
    --font-size-xlarge: 1.25rem;
    --font-size-h1: 2.8125rem;
    --font-size-h1-tablet: 2rem;
    --font-size-h1-mobile: 1.75rem;
    --font-size-h2: 1.75rem;
    --font-size-h2-mobile: 1.375rem;
    --font-size-h3: 1.5rem;
    --font-size-h3-mobile: 1.25rem;
    --font-size-h4: 1rem;
    --font-size-h5: 1rem;
    --font-size-h6: 1rem;
    --font-size-widget: 1.0625rem;
    --font-size-widget-title: 1.25rem;

    /* Type: Line height */
    --base-line-height: 1.47;
    --line-height-heading: 1.2;

    /* Type: Font weight */
    --font-weight-light: 300;
    --font-weight-regular: 400;
    --font-weight-medium: 500;
    --font-weight-semibold: 600;
    --font-weight-bold: 700;
    --font-weight-extrabold: 800;
    --font-weight-body: 300;
    --font-weight-heading: 300;
    --font-weight-nav: 400;

    /* Colors */
    --color-1: #002c20;
    --color-2: #00c793;
    --color-3: #00936c;
    --color-4: #007152;
    --color-5: #00553e;
    --color-6: #f2f0ef;

    --color-body: #002c20;
    --color-body-bg: var(--color-gray);
    --color-text: #002c20;
    --color-text-light: #fcfcfc;
    --color-separator: var(--color-gray);
    --color-gray: #f2f0ef;
    --color-gray-light: #faf9f9;
    --color-header-bg: #ebebeb;
    --color-black: #000;

    /* Link colors */
    --base-link-color: var(--color-3);
    --hover-link-color: var(--color-2);
    --base-button-color: var(--color-3);
    --hover-button-color: var(--color-2);

    /* Border color */
    --base-border-color: var(--color-gray);
    --dark-border-color: var(--color-1);
    --base-border: 1px solid var(--base-border-color);
    --dark-border: 1px solid var(--dark-border-color);
    --light-border-color: #f0f0f0;
    --light-border: 1px solid var(--light-border-color);
    --border-radius: 3px;

    /* Shadow color */
    --base-shadow-color: #fcfcfc;

    /* Box shadow */
    --base-box-shadow: rgba(0, 0, 0, 0.2) 0 3px 10px 2px;

    /* box-color */
    --box-color: #f4f4f4;

    /* Spacing */
    --spacing-1: 10px;
    --spacing-2: 20px;
    --spacing-3: 30px;
    --spacing-4: 40px;
    --spacing-5: 50px;
    --spacing-6: 60px;
    --spacing-7: 70px;
    --spacing-8: 80px;
    --spacing-9: 90px;
    --spacing-10: 100px;
  }

  .header {
    font-family: var(--base-font-family);
    font-size: var(--base-font-size);
    font-weight: var(--font-weight-body);
    line-height: var(--base-line-height);
    box-sizing: border-box;
  }

  .header__main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    min-height: 60px;
    z-index: 25;
    position: relative;

    @media (min-width: 1041px) {
      min-height: 80px;
    }
  }

  .header__main--right {
    .top {
      display: none;
      flex-direction: row;
      flex-wrap: nowrap;

      @media (min-width: 1041px) {
        display: flex;
      }
    }
  }

  .logo {
    flex-grow: 2;
    display: flex;
  //  padding-left: var(--spacing-2);

    // @media (min-width: 1041px) {
    //   padding-left: var(--spacing-7);
    // }
  }

  .logo__image {
    margin: 0;
    max-width: 150px;
  }

  .hamburger {
    appearance: none;
    display: block;
    position: relative;
    top: 1px;
    padding: 5px 0 5px 5px;
    width: 25px;
    height: 30px;
    color: #333;
    border: 0;
    background: none;
    transition: opacity 0.3s ease;

    &:hover,
    &:focus {
      opacity: 0.8;
    }

    &:hover {
      cursor: pointer;
    }

    @media (min-width: 1041px) {
      display: none;
    }
  }

  .hamburger__icon,
  .hamburger__icon::before,
  .hamburger__icon::after {
    background-color: #333;
    height: 2px;
    width: 20px;
    transition: all 0.15s ease-in-out;
  }

  .hamburger__icon {
    position: absolute;
    top: 14px;
    left: 5px;
  }

  .hamburger__icon::before,
  .hamburger__icon::after {
    content: '';
    position: absolute;
    top: -7px;
    left: 0;
  }

  .hamburger__icon::after {
    top: 7px;
  }

  .hamburger.active .hamburger__icon {
    background: transparent;
  }

  .hamburger.active .hamburger__icon::before {
    transform: rotate(-45deg);
    top: 0;
  }

  .hamburger.active .hamburger__icon::after {
    top: 0;
    transform: rotate(45deg);
  }

  /* Close variant */
  .hamburger__close-icon {
    display: inline-block;
    position: relative;
    top: calc(-0.5em + 1px);
    width: 20px;
  }

  .hamburger__close-icon::before,
  .hamburger__close-icon::after {
    position: absolute;
    height: 2px;
    width: 20px;
    background: #333;
    border-radius: 1px;
    content: '';
  }

  .hamburger__close-icon::before {
    top: 0;
    left: 0;
    transform: rotate(45deg);
  }

  .hamburger__close-icon::after {
    right: 0;
    top: 0;
    transform: rotate(-45deg);
  }

  .menu-wrapper {
    height: 80px;
    display: flex;
    align-items: center;
    padding-right: var(--spacing-2);

    @media (min-width: 1041px) {
      padding-right: var(--spacing-7);
    }
  }

  body.has-subnav {
    .header {
      @media (min-width: 1041px) {
        margin-bottom: 80px;
      }
    }
  }

  .menu-sites {
    display: flex;
    align-items: center;
    font-size: 14px;
    list-style: none;
    margin: 0;
    padding: 20px 35px;
    text-transform: uppercase;
    height: 40px;
    background-color: var(--color-gray);

    a {
      text-decoration: none;
      color: var(--color-text);
      padding-bottom: 2px;
    }

    li {
      padding: 0 9px;
    }

    li.menu-sites__item--current {
      a {
        font-weight: var(--font-weight-medium);
        color: var(--color-3);
        border-bottom: 1px solid var(--color-3);
      }
    }
  }

  .header-search-mobile__form-wrap {
    padding: var(--spacing-2);
    border-bottom: var(--base-border);
    background: var(--color-header-bg);
  }

  .header-search-mobile__form {
    display: flex;
  }

  .header-search-mobile__field {
    border: var(--light-border);
    border-radius: var(--border-radius);
    width: 100%;
    height: 40px;

    @media (min-width: 721px) {
      height: 50px;
    }
  }

  .btn.search-form-mobile__button {
    display: flex;
    justify-content: space-between;
    margin: 0 0 0 var(--spacing-1);
    padding: var(--spacing-1) var(--spacing-2);

    @media (min-width: 721px) {
      padding: var(--spacing-1) var(--spacing-4);
    }

    svg {
      margin-left: var(--spacing-1);
      width: 1rem;
    }
  }

  .header-search {
    display: flex;
    align-items: center;
  }

  .header-search__form-wrap {
    position: relative;
    overflow: hidden;
    margin: 0;
    list-style: none;
    width: 100%;
  }

  .header-search__form {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0 auto;
    height: 40px;
  }

  .header-search__field {
    position: relative;
    background: transparent;
    font-family: var(--base-font-family);
    color: var(--color-3);
    flex-grow: 2;
    padding: 0 !important;
    border: none;
    font-size: 18px;
    height: 40px;
    margin-right: 40px;
    transition: all 0.5s;
    max-width: 0;
  }

  .header-search__form.active .header-search__field {
    background: var(--color-gray-light);
    max-width: 100%;
    padding: 0 30px !important;
  }

  .header-search__field::placeholder {
    color: var(--color-3);
    opacity: 0.65;
  }

  .header-search__button {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background: var(--color-gray-light);
    border: none;
    cursor: pointer;
    user-select: none;
    padding: 0 30px;
    height: 40px;
    transition: all 0.35s;

    &:hover {
      background: var(--color-gray);
    }
  }

  .header-search__form.active .header-search__button {
    background: var(--color-gray-light);
    padding: 0 30px 0 10px;
  }

  .header-search__helper-text {
    color: var(--color-3);
    font-weight: var(--font-weight-regular);
    overflow: hidden;
    max-width: 100%;
    transition: all 0.35s;
  }

  .header-search__form.active .header-search__helper-text {
    margin-right: 0;
    max-width: 0;
  }

  .header-search__icon.icon-theme-search {
    font-size: 19px;
    font-weight: bold;
    color: var(--color-3);
  }

  input[type='search'],
  button {
    padding: 1em;
  }

  body.search .search-form {
    @media (min-width: 721px) and (max-width: 1040px) {
      margin: 0 var(--spacing-2) var(--spacing-4);
    }
  }

  .search-not-found-message {
    margin: 0 auto;
    max-width: var(--max-width-list);

    @media (min-width: 721px) and (max-width: 1040px) {
      margin: 0 var(--spacing-2);
    }
  }

  .search-form {
    display: flex;
    margin: var(--spacing-2) auto;
    padding: var(--spacing-2);
    max-width: var(--max-width-list);
    position: relative;
    background-color: #fff;

    @media (min-width: 721px) {
      margin: var(--spacing-4) auto var(--grid-gutter) auto;
      padding: var(--spacing-5);
    }
  }

  input[type='search'].search-form__field {
    border: var(--light-border);
    border-radius: var(--border-radius);
    width: 100%;
    height: var(--spacing-4);
  }

  .search-form__submit {
    background: #333;
    border: 1px solid #333;
    color: #d8d8d8;
    font-family: var(--base-font-family);
    border-top-right-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
  }

  .search-form__icon {
    width: 1rem;
  }

  .search-form .ep-autosuggest-container {
    width: 100%;
    position: inherit;
    border: none;

    .ep-autosuggest {
      width: calc(100% + 3em);
      left: 0;
      border: none;
      z-index: 0;
      right: 0;
      margin-top: 3px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.18);
      padding: var(--spacing-1) 16px;

      @media (min-width: 1041px) {
        padding: var(--spacing-1) 1em;
      }
    }

    .ep-autosuggest > ul {
      width: 100%;
      max-width: var(--max-width);
      margin: 0 auto !important;
      padding: 0 var(--spacing-2);
      list-style-type: square;
    }

    .ep-autosuggest > ul > li {
      height: auto;
      width: 100%;
      font-size: var(--medium-font-size);
      font-family: var(--base-font-family);

      :hover,
      :focus,
      :active {
        background-color: #fff;
        text-decoration: underline;
      }

      @media (min-width: 1041px) {
        font-size: 100%;
      }
    }

    .ep-autosuggest > ul > li span {
      padding: 8px 0;
    }

    .selected {
      background-color: #fff;
      border: none;
    }

    input[type='search'].header-search__field {
      box-shadow: none;
      border: none !important;
    }
  }

  .menu-mobile {
    margin: 0;
    padding: 0;
    list-style: none;
    line-height: var(--line-height-heading);

    @media (min-width: 1041px) {
      display: none;
    }

    a {
      opacity: 1;
    }
  }

  .menu-mobile ul,
  .menu-mobile ul li {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .menu-mobile__link {
    display: block;
    padding: var(--spacing-2);
    color: var(--color-3);
    border-bottom: var(--base-border);
    font-size: 19px;
    padding-right: 40px;
    text-decoration: none;

    &:hover {
      color: var(--hover-link-color);
    }

    &:focus {
      color: inherit;
    }
  }

  .menu-mobile__sub-menu__link {
    display: block;
    color: var(--color-3);
    padding: var(--spacing-2);
    padding-left: var(--spacing-3);
    background-color: var(--color-body-bg);
    border-bottom: var(--base-border);
    font-size: 17px;
    text-decoration: none;
  }

  .menu-mobile__sub-menu {
    display: none;
  }

  .menu-mobile__item--current-ancestor .menu-mobile__sub-menu {
    display: block;
  }

  .menu-mobile__item--has-children {
    position: relative;
  }

  .menu-mobile__item--has-children::before,
  .menu-mobile__item--has-children::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 2px;
    background-color: var(--color-3);
    top: 30px;
    transition: transform 0.15s;
  }

  .menu-mobile__item--has-children::before {
    transform: rotate(-45deg);
    right: 22px;
  }

  .menu-mobile__item--has-children::after {
    transform: rotate(45deg);
    right: 28px;
  }

  .menu-mobile__item--has-children.menu-mobile__item--has-children--is-active::before {
    transform: rotate(45deg);
  }

  .menu-mobile__item--has-children.menu-mobile__item--has-children--is-active::after {
    transform: rotate(-45deg);
  }

  .menu-mobile__item--current {
    > .menu-mobile__sub-menu__link {
      color: var(--color-black);
    }
  }

  .menu-mobile__item--current,
  .menu-mobile__item--current-ancestor {
    > .menu-mobile__link {
      color: var(--color-black);
    }
  }

  .menu-overlay .site-switcher .site-list {
    padding: var(--spacing-2);
    border-left: 0;
  }

  .menu-overlay {
    position: absolute;
    overflow: auto;
    box-sizing: border-box;
    width: 100%;
    background-color: #fff;
    transition: transform 0.15s ease-in-out;
    -webkit-overflow-scrolling: touch;
    border-top: var(--base-border);
    z-index: 10;
    transform: translateY(-100%);

    @media (min-width: 1041px) {
      display: none;
    }
  }

  .menu-overlay.menu-overlay--open {
    transform: translateY(0);
  }

  .menu-overlay-inner {
    display: flex;
    flex-direction: column;
  }

  .hamburger--close {
    display: none;
    align-self: flex-end;
  }

  .header__main {
    .menu {
      display: none;
      margin: 0;
      padding: 0 0 0 40px;
      list-style: none;
      font-size: var(--base-font-size);
      text-align: right;
      height: 100%;

      @media (min-width: 1041px) {
        display: flex;
        justify-content: flex-end;
      }
    }

    .menu__item {
      display: inline-block;
      margin-right: var(--spacing-3);
      padding: 0;
      height: 100%;

      &:last-child {
        margin-right: 0;
      }
    }

    .menu__link {
      position: relative;
      display: flex;
      align-items: center;
      height: 100%;
      color: var(--color-1);
      font-weight: var(--font-weight-light);
      text-decoration: none;

      &:hover,
      &:focus {
        color: var(--color-black);
      }
    }

    .menu__item--current,
    .menu__item--current-ancestor,
    .menu--inverted .menu__item--current,
    .menu--inverted .menu__item--current-ancestor {
      .menu__link {
        color: var(--color-black);

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          background-color: var(--color-black);
          height: 4px;
          width: 100%;
        }
      }
    }

    .menu__item .menu__sub-menu {
      display: none;
      margin: 0 auto;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      max-height: var(--spacing-8);
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: center;
      background: #fff;
      padding: 0 var(--spacing-2);
      border-top: 1px solid #eee;
      list-style: none;
      box-sizing: border-box;
      height: 100%;

      @media (min-width: 1041px) {
        padding: 0 var(--spacing-7);
      }
    }

    .menu__item.menu__item--current .menu__sub-menu,
    .menu__item.menu__item--current-ancestor .menu__sub-menu {
      display: flex;
    }

    .menu__sub-menu__item {
      position: relative;
      display: flex;
      align-items: center;
      margin-left: var(--spacing-3);
      padding: 0;
      height: 100%;
    }

    .menu__sub-menu__item.menu__item--current {
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        background-color: var(--color-black);
        height: 4px;
        width: 100%;
      }
    }

    .menu__sub-menu__link {
      display: flex;
      align-items: center;
      color: var(--color-3);
      text-decoration: none;
      height: 100%;

      &:hover,
      &:focus {
        color: var(--color-black);
      }
    }

    .menu__sub-menu__item.menu__item--current .menu__sub-menu__link {
      color: var(--color-black);
    }
  }

  /* IE10+ fallback */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .header__main {
      .menu__sub-menu--is-visible {
        top: calc(100% + 44px);
      }
    }
  }

  /* ---------- FOOTER ---------- */

  .footer-container {
    background-color: #fff;
  }

  .footer-wrapper {
    box-sizing: content-box;
    max-width: var(--max-width);
    margin: 0 auto;

    @media (min-width: 721px) {
      display: flex;
      justify-content: space-between;
    }
  }

  .after-footer-wrapper {
    max-width: var(--max-width);
    margin: 0 auto;
    padding: var(--spacing-4) var(--spacing-2);

    @media (min-width: 721px) {
      padding: var(--spacing-4);
    }

    @media (min-width: 1041px) {
      padding: var(--spacing-5) var(--spacing-2);
    }

    .textwidget p {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      flex-wrap: wrap;

      img {
        max-height: 40px;
        width: auto;
        margin: 0 var(--spacing-4) var(--spacing-2) 0;
        opacity: 0.5;
        filter: grayscale(50%);
      }
    }
  }

  .footer {
    padding: var(--spacing-4) var(--spacing-2);
    width: 100%;
    box-sizing: border-box;

    @media (min-width: 721px) {
      padding: var(--spacing-4);
    }

    @media (min-width: 1041px) {
      display: flex;
      align-content: space-between;
      padding: var(--spacing-5) var(--spacing-2);
    }
  }

  .footer .widget {
    margin-bottom: var(--spacing-5);

    &:last-child {
      margin: 0;
      padding: 0;
    }

    @media (min-width: 1041px) {
      margin: 0;
      padding-right: var(--spacing-4);
      flex: 1;
    }

    p,
    a {
      font-weight: var(--font-weight-light);
      font-size: var(--base-font-size);
    }

    a {
      text-decoration: none;
      color: var(--color-3);

      &:hover,
      &:focus {
        opacity: 0.8;
      }
    }

    p {
      margin: 1em 0;
      max-width: none;
    }
  }

  .footer .widgettitle {
    color: var(--color-text);
    line-height: 1.25;
    font-weight: var(--font-weight-regular);
    margin-top: 0;
    margin-bottom: var(--spacing-3);
    text-transform: uppercase;
    max-width: none;
  }

  .footer .widget ul {
    margin: 0;
    padding: 0;
    display: block;
    font-size: var(--font-size-large);
  }

  .footer .widget li {
    line-height: 1.25;
    margin-bottom: var(--spacing-2);
    display: block;
    list-style-type: none;
  }

  .miljoezone-some {
    .some-container {
      overflow: hidden;

      .some-links__wrapper {
        margin: 0 -10px;
        overflow: hidden;
      }

      a.some-links {
        float: left;
        margin: 0 var(--spacing-1) var(--spacing-2);
      }

      svg {
        vertical-align: middle;
      }

      .widget__description {
        clear: both;
        color: var(--color-5);
        display: block;
        float: none;
        width: 100%;
      }
    }
  }
