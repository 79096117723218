@import 'mixins';

html,
body {
  min-height: 100vh;
}

body {
  min-width: 320px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $info;
}

select {
  padding: 0 15px;
}
main {
  @include media-breakpoint-down(sm) {
    padding: 0 10px;
  }
}
main .mz-flow .header .title {
  @include media-breakpoint-down(sm) {
    font-size: 3.1rem;
  }
}

.col-mb {
  margin-bottom: $col-mb;
}
.btn-lg {
  min-width: 180px;
}
/* input validation */
.incorrect {
  .form-group input.form-control.ng-invalid,
  .form-group textarea.form-control.ng-invalid {
    border-color: $danger;
    &:hover,
    &:focus {
      box-shadow: none;
    }
  }
}
p {
  font-size: 1.15rem;
}
.form-group {
  &.is-invalid input,
  &.is-invalid textarea {
    border-color: $danger;

    &:hover,
    &:focus {
      box-shadow: none;
    }
  }

  &.is-valid input,
  &.is-valid textarea {
    border-color: $success;

    &:hover,
    &:focus {
      box-shadow: none;
    }
  }
}
input.form-control::placeholder {
  color: $gray-500;
}

.no-padding {
  padding: 0;
}

.mz-flow {
  min-height: 600px;
  .mz-flow-page li {
    list-style-type: disc;
    list-style-position: inside;
    padding: 10px 0 10px 20px;
    text-indent: -1em;
  }

  @include media-breakpoint-down(sm) {
    padding: 20px 0;
  }

  .regnumber {
    text-transform: uppercase;
    font-size: 1.5rem;
  }

  .btn-back,
  .btn-next {
    .btn-text {
      font-size: 1.4rem;
      color: $gray-900;
      transition: 0.3s color;
    }
    &:hover {
      text-decoration: none;
      .btn-text {
        color: $success;
      }
    }
  }

  .header {
    .description {
      line-height: 1.5rem;
      font-size: $font-size-text;
      margin-bottom: 1.5rem;
    }
    .title {
      color: $primary;

      margin-bottom: 1.25rem;
    }
    .font-lg {
      font-size: 2.5rem;
    }
  }

  .card {
    border: none;
    min-height: 450px;
    @include media-breakpoint-up(md) {
      padding: 20px 50px 0;
      box-shadow: 0 10px 10px 3px rgba(0, 0, 0, 0.3);
    }
    th {
      font-weight: 400;
    }
  }
}

/* button */
.btn-default {
  background: transparent;
  border: 1px solid $gray-300;
  color: $primary;
}

.btn-secondary,
.btn-secondary:hover {
  color: $primary;
}

.btn-success {
  color: $white;
  box-shadow: 0 5px 10px rgba($success, 0.4);
}

.btn {
  text-transform: none !important;
  &:focus,
  &.focus {
    box-shadow: none;
  }
}

.primary-icon-link {
  color: $success;
  text-decoration: none !important;
  transition: 0.3s color;
  display: flex;
  align-items: center;

  [class^='pp-icon-'] {
    font-size: 140%;
  }

  &:hover,
  &:focus {
    color: darken($success, 10%);
  }
}

.dropdown-toggle::after {
  margin-left: 0.75rem;
}

/* popver */
.popover {
  border: none;
  .arrow::before {
    border: none;
  }
  .popover-body {
    padding: 0;
  }
}

/* modal */
.modal-md {
  .modal-dialog {
    max-width: $modal-md;
    color: $primary;
  }
}

.modal-width-lg .modal-dialog {
  max-width: $modal-lg;
  color: $primary;
}

.modal-xl .modal-dialog {
  max-width: $modal-xl;
  color: $primary;
}

.modal-content {
  box-shadow: 0 8px 24px 0 $modal-shadow-color;
  border: none;
}

/* status */
.status {
  .paid {
    color: $status-paid;
  }
  .unpaid {
    color: $status-unpaid;
  }
  .cancelled {
    color: $status-cancelled;
  }
  .appealed {
    color: $status-appealed;
  }
}

.paid .status-value {
  color: $status-paid;
}

.unpaid .status-value {
  color: $status-unpaid;
}

.cancelled .status-value {
  color: $status-cancelled;
}

.appealed .status-value {
  color: $status-appealed;
}

/* appeal */
.appeal {
  .modal-footer {
    padding: 1.75rem 0 0;
    justify-content: flex-start;

    .btn {
      padding: 0.5rem 2rem;
      font-size: 0.75rem;
    }
  }
}

/* carousel */
.carousel-item,
.carousel-inner,
.carousel {
  &:focus,
  &:hover {
    outline: none;
  }
}

/* hide arrows on numbers inputs */
input[type='number'].hide-arrow::-webkit-inner-spin-button,
input[type='number'].hide-arrow::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* blocked scroll */
.no-scroll {
  @include media-breakpoint-down(sm) {
    overflow: hidden;
    height: 100vh;
  }

  body {
    .dropdown-menu {
      @include media-breakpoint-down(sm) {
        padding-bottom: 90px;
      }
    }
  }
}
.mz-flow .card {
  @include media-breakpoint-down(sm) {
    margin-left: -10px;
    margin-right: -10px;
  }
  .table-content {
    @include media-breakpoint-down(sm) {
      margin-left: -7px;
      margin-right: -7px;
    }
  }
}

.form-group-part {
  position: static;
  min-height: 100%;
  display: flex;

  .dropdown-toggle {
    padding: 1rem 1rem 1rem 2rem;

    @include media-breakpoint-down(sm) {
      padding-left: 1rem;
    }

    &::after {
      display: inline-block;
      color: $input-color;
    }
  }

  .dropdown-menu {
    width: 100% !important;
    top: 100% !important;
    left: 0 !important;
    transform: none !important;
    margin: 0 !important;

    &::before {
      display: none;
    }

    @include media-breakpoint-down(sm) {
      height: auto;
      flex-direction: column-reverse;
      left: 0 !important;
      bottom: 0;
      right: 0;
      z-index: 100;
      padding-top: $header-xs-height !important;
      position: fixed !important;
    }

    &.show {
      @include media-breakpoint-down(sm) {
        display: block;
      }
    }

    .btn {
      @include media-breakpoint-down(sm) {
        flex-shrink: 0;
      }
    }
  }

  .flag {
    border-radius: 0;
    box-shadow: none;
  }

  .selected-item {
    min-width: 78px;
    display: flex;
    align-items: center;

    .flag-wrap {
      margin-left: 20px;
      top: 0;
    }

    &::after {
      transform: translateY(-3px);
    }

    .flag {
      top: 50%;
      box-shadow: none;
      transform: translateY(-50%) scale(0.6);
    }
  }
}

.ngb-tp-input-container {
  @include media-breakpoint-down(md) {
    width: 3rem !important;
    font-size: 16px;
  }
}

.ngb-dp-header .ngb-dp-month-name {
  @include media-breakpoint-down(sm) {
    font-size: 18px;
  }
}

input[type='password'] {
  font-family: Verdana, sans-serif !important;
}

.account-login-form [type='submit'] .pp-icon-arrow-back,
.pp-icon-arrow-back.inverted {
  transform: scale(-1);
  font-size: 125%;
}

.vehicles-list {
  li {
    border-bottom: 1px solid $gray-500;
    font-size: 115%;
  }
}

.modal-open {
  padding: 0 !important;
}

.page-content {
  @include media-breakpoint-up(xl) {
    padding-left: 50px;
    padding-right: 30px;
  }

  .page-header {
    @include media-breakpoint-down(sm) {
      padding: 15px 0;
    }
  }

  .card-body {
    @include media-breakpoint-down(sm) {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

.page-header {
  border-bottom: 2px solid $success;
  padding: 30px 0;
  min-height: 110px;
}

.relative-control {
  position: relative;

  .form-control {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    &:focus {
      box-shadow: none;
      border-color: $input-border-color;
    }
  }
}

.cards-holder {
  max-width: 220px;
  img {
    display: inline-block;
    vertical-align: top;
    max-width: 100%;
  }
}

.create-account-nav {
  display: flex;
  padding: 0;
  margin: 0 auto 50px;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
  list-style: none;
  position: relative;
  z-index: 1;

  li {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid $success;
    color: $success;
    background: $body-bg;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 120%;
    font-weight: bold;
    position: relative;
    z-index: 5;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &::after {
      content: '';
      position: absolute;
      left: calc(100% + 2px);
      top: 50%;
      transform: translateY(-50%);
      height: 2px;
      width: 50vw;
      background-color: $success;
      z-index: -1;
      pointer-events: none;
    }

    &:first-child {
      &::before {
        content: '';
        position: absolute;
        right: 100%;
        top: 50%;
        transform: translateY(-50%);
        height: 2px;
        width: 50vw;
        background-color: $success;
        z-index: -1;
        pointer-events: none;
      }
    }

    &.active {
      background-color: $success;
      color: $white;
    }

    &.disabled {
      border-color: $gray-500;
      color: $gray-500;
      pointer-events: none;

      &::after {
        background-color: $gray-500;
      }
    }
  }

  &.complete {
    li {
      background-color: $success;
      color: $white;
      pointer-events: none;
      border-color: $success;

      &::after {
        background-color: $success;
      }
    }
  }
}

.page-title {
  font-size: 46px;
  letter-spacing: 0.74px;
  font-weight: 700;
  margin-bottom: 22px;
}

.card-body {
  &.page-body {
    @include media-breakpoint-up(md) {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}

.card-container {
  max-width: 100%;
}

.custom-container {
  max-width: 758px;
  margin: 0 auto;
  padding: 0 15px;
}

.custom-min-container {
  max-width: 400px;
}

.btn-success {
  background-color: $color-3;
  border-color: $color-3;
  i {
    &:before {
      display: inline-block;
      vertical-align: top;
      transition: transform 0.3s;
    }
  }

  &:not(:disabled) {
    &:hover,
    &:focus {
      background-color: $color-4;
      border-color: $color-4;

      i.pp-icon-arrow-back {
        &:before {
          transform: translateX(-5px);
        }
      }
    }
  }
}

.tip-btn {
  [class^='pp-icon-'] {
    color: $white;

    &::before {
      color: inherit;
    }
  }
}

.card-container,
.add-card-container {
  .jp-card-container {
    transform: none !important;
    width: 320px;
    max-width: 100%;
  }

  .jp-card {
    min-width: 320px;
  }
  .jp-card-name {
    font-size: 18px;
  }
}
.card-number-panel {
  border: 1px solid #9b9;
  padding: 6px;
  font-size: 1.25rem;
}

.message-holder {
  min-height: 1.5rem;
  align-items: flex-start;
}

.total {
  font-weight: 700;
  font-size: 50px;
}

.thumbnail {
  max-width: 200px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin: 30px 0;
  word-break: break-all;

  img {
    max-width: 100%;
  }

  .btn {
    position: absolute;
    z-index: 2;
    border-radius: 50%;
    font-size: 24px;
    width: 40px;
    height: 40px;
    top: 0;
    left: 0;
    transform: translate(-30%, -30%);

    span {
      position: absolute;
      top: 45%;
      left: 52%;
      transform: translate(-50%, -50%);
    }
  }
}

// // MZ

// .card-footer {
//    font-size: 0.75rem;
// }

// .mh-450{
//   min-height: 450px;
// }

// @include media-breakpoint-down(xs) {
//   h1 {
//     font-size: $font-size-h1-mobile;
//   }
//   h2 {
//     font-size: $font-size-h2-mobile;
//   }
//   .mz-flow.card{
//     padding-left: 1.5rem;
//     padding-right: 1.5rem;
//   }
//   .card-header
//   {
//     padding: 0;
//   }
// }

// .btn.btn--link-back {
//   position: absolute;
//   margin-left: -20px;
//   margin-top: -5px;
//   font-size: $font-size-h3;
// }

// .btn.btn--link-only, .btn.btn--link-back {
//   color: $color-3;
//   background-color: transparent;
//   padding: 0;
//   font-weight: $font-weight-light;
//   font-size: $base-font-size;
// }
// .btn.btn--link-only:after {
//   content: "\e900";
//   font-family: icons-theme!important;
//   font-size: $font-size-h2-mobile;
//   vertical-align: middle;
//   border: 1px solid $color-3;
//   border-radius: 50%;
//   margin-left:  $spacing-1;
//   -webkit-transition: all .2s ease-out;
//   transition: all .2s ease-out;
//   position: relative;
//   top: -1px;
// }

// .btn.btn--link-back:before {
//   content: "\e900";
//   font-family: icons-theme!important;
//   font-size: $font-size-h2-mobile;
//   vertical-align: middle;
//   margin-left:  $spacing-1;
//   -webkit-transition: all .2s ease-out;
//   transition: all .2s ease-out;
//   position: relative;
//   top: -1px;
//    transform: rotate(180deg);
// }
